.main-user-table-div{
    width: 100%;
    height: fit-content;
}

.main-user-table-div
.table-row{
    width: 100%;
    box-sizing: border-box;
    padding-inline: 43px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-user-table-div
.table-row
.table-column{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.main-user-table-div
.table-row
.column-left{
    width: 40%;
}

.main-user-table-div
.table-row
.column-mid{
    width: 40%;
}

.main-user-table-div
.table-row
.column-right{
    width: 20%;
}

.main-user-table-div
.table-head{
    height: 65px;
    border: 1px solid #FFB27C;
}

.main-user-table-div
.table-head
.t-head-title{
    color: #1F384C;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; /* 127.778% */
    letter-spacing: 0.5px;
}

.main-user-table-div
.table-banner{
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding-inline: 44px;

    background-color: #FFB27C;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    color: #FFF;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
}

.main-user-table-div
.table-body{
    height: 100px;

    border-top: 1px solid #C7CBD9;
}

.main-user-table-div
.table-body
.user-info{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.main-user-table-div
.table-body
.user-info
.profile-photo{
    width: 57.778px;
    aspect-ratio: 1/1;
}

.main-user-table-div
.table-body
.user-info
.user-name{
    color: #9D9FA2;

    font-size: 14.444px;
    font-style: normal;
    font-weight: 500;
    line-height: 143.8%; /* 20.771px */
    letter-spacing: 0.433px;
}

.main-user-table-div
.table-body
.access-tags-column{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
}

.main-user-table-div
.table-body
.actions-column{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.main-user-table-div
.table-body
.actions-column
.modify-btn{
    border: none;
    padding: 0;

    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.25px;
}

.main-user-table-div
.table-body
.actions-column
.toggle-user{
    width: 45px;
    height: 25px;
    border-radius: 12.5px;
    box-sizing: border-box;
    padding: 3px;

    background-color: transparent;

    position: relative;

    border: 2px solid #686868;
}

.main-user-table-div
.table-body
.actions-column
.toggle-user
span{
    position: absolute;
    height: calc(100% - 3px);
    aspect-ratio: 1/1;
    border-radius: 50%;
    margin: 1.5px;

    left: 0;
    top: 0;
    box-sizing: border-box;

    background-color: #FF5C00;
}

.main-user-table-div
.table-body
.actions-column
.toggle-user
.active-toggle{
    left: initial;
    right: 0 !important;
}

.main-user-table-div
.pagination-btn{
    cursor: pointer;
    border: none;
    background-color: transparent;

    color: #FF5C00;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 133.333% */
    letter-spacing: 0.5px;
}