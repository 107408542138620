.red-btn-global{
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 3.4375rem;
    background: #00C50A;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFF;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}