.contact-us-main-div{
    width: 100%;
    box-sizing: border-box;
    padding: 27px 56px;
    max-width: 1160px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.contact-us-main-div
.alert-box{
    margin-top: 1.5rem;
    width: 100%;
    height: fit-content;

    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-us-main-div
.alert-box
.text{
    margin: 0;
    word-wrap: normal;
    color: #DA2128;
    text-align: center;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 82%;
}

.contact-us-main-div
.page-title{
    margin-bottom: 54px;
    color: #1F384C;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; /* 115% */
    letter-spacing: 0.5px;
}

.contact-us-main-div
.note{
    margin-bottom: 45px;
    color: #3B5061;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 127.778% */
    letter-spacing: 0.5px;

    span{
        color: #FF5C00;
    }
}

.contact-us-main-div
.contact-input-container{
    width: 100%;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 42px;
    row-gap: 14px;
    flex-wrap: wrap;
}

.contact-us-main-div
.contact-us-input{
    box-sizing: border-box;
    width: calc(50% - 21px);
    min-width: 300px;
    height: 46px;
    padding-inline: 0.9rem;

    border-radius: 23px;
    border: 1.5px solid #FFB27C;
    /* box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.30) inset; */

    color: #FF5C00;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 550;
    line-height: 143.8%; /* 1.25825rem */
}

.contact-us-main-div
.contact-us-input::placeholder{
    color: #FF5C00;
}

.contact-us-main-div
.contact-us-input:focus{
    color: #FF5C00;
    outline: none;
}

.contact-us-main-div
.enter-comments-note{
    margin-bottom: 14px;
    color: #3B5061;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 127.778% */
    letter-spacing: 0.5px;
}

.contact-us-main-div
.textarea{
    margin-bottom: 2.25rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0.75rem 1rem;
    overflow-y: auto;

    border-radius: 16px;
    border: 1.5px solid #FFB27C;
    /* box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.30) inset; */

    color: #FF5C00;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 550;
    line-height: 143.8%;
}

.contact-us-main-div
.textarea::placeholder{
    color: #FF5C00;
}

.contact-us-main-div
.textarea:focus{
    outline: none;
    color: #FF5C00;
}