.active-driver-card-main-div{
    width: 100%;
    height: 100%;

    position: relative;
    overflow: hidden;
}

.active-driver-card-main-div
.report-btn{
    cursor: pointer;
    width: 130.773px;
    height: 38.392px;

    position: absolute;
    top: 16px;
    right: 24px;

    border-radius: 5px;

    background-color: #EBFFE1;
    border: 0.5px solid #e3f0dd;
    filter: drop-shadow(0px 2px 1px rgba(64, 72, 82, 0.05));

    color: #00A510;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5px;
}

.active-driver-card-main-div
.all-text-div{
    text-align: left;
    max-height: 20%;
}

.active-driver-card-main-div
.all-text-div
.main-title{
    margin-bottom: 9.6px;
    color: #000;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.active-driver-card-main-div
.all-text-div
.card-sub-title{
    color: #000;
    margin-bottom: 12px;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: 0.5px;
    margin-bottom: 12px;
}

.active-driver-card-main-div
.all-text-div
.percentage-text{
    margin-bottom: 28px;
    color: #149D52;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 100% */
    letter-spacing: 0.5px;
}

.active-driver-card-main-div
.all-text-div
.time-period-text{
    color: #000;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
    letter-spacing: 0.5px;
}

.active-driver-card-main-div
.all-text-div
.percentage-text
span{
    color: #737B8B;
}

.active-driver-card-main-div
.table-container{
    width: 100%;
    aspect-ratio: 1/1;
    overflow: scroll;
    /* margin-top: -30px; */
}

.active-driver-card-main-div
.table-container::-webkit-scrollbar{
    display: none;
}

.active-driver-card-main-div
.table-container
table{
    width: 100%;
}

.active-driver-card-main-div
.table-container
table thead tr th{
    color: #000;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 183.333% */
    letter-spacing: 0.5px;
}

.active-driver-card-main-div
.table-container
table tbody tr{
    width: 100%;
    height: fit-content;
    border-bottom: 0.5px solid #DBE5EB;
}

.active-driver-card-main-div
.table-container
table tbody tr td{
    text-transform: capitalize;
    color: #273240;

    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.5px;
}

.active-driver-card-main-div
.table-container
table tbody tr
.table-data-profile{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.active-driver-card-main-div
.table-container
table tbody tr
.table-data-profile
.profile-avatar{
    width: 31px;
    height: 31px;
}