.analytics-main-div{
    width: 100%;
    min-height: calc(100vh);

    box-sizing: border-box;
    padding: 36px 56px;
}

.analytics-main-div
.filter-div{
    margin-bottom: 30px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.analytics-main-div
.filter-div
.filter-btn{
    cursor: pointer;
    width: 164px;
    height: 21px;

    background-color: transparent;

    border-radius: 10.5px;
    border: 1px solid #FFB27C;

    color: #682B00;
    text-align: center;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px; /* 162.5% */
    letter-spacing: 0.5px;
}

.analytics-main-div
.filter-div
.filter-btn:hover{
    background-color: #FFB27C;
}

.analytics-main-div
.page-heading{
    margin-bottom: 48px;

    text-align: left;
    color: #1F384C;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; /* 115% */
    letter-spacing: 0.5px;
}

.analytics-main-div
.graph-grid-div{
    width: 100%;
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 433px 433px;
}

.analytics-main-div
.graph-grid-div
.analylics-grid-padding{
    box-sizing: border-box;
    padding: 10px;
    padding-inline: 20px;
}


.analytics-main-div
.graph-grid-div
.bar-graph-card{
    width: 100%;
    height: 100%;
    grid-row: 1;
    grid-column: 1/3;

    border-right: 1px solid #c8cbd9;
    border-bottom: 1px solid #c8cbd9;
}

.analytics-main-div
.graph-grid-div
.line-graph-card{
    width: 100%;
    height: 100%;
    grid-row: 1;
    grid-column: 3;

    border-bottom: 1px solid #c8cbd9;
}

.analytics-main-div
.graph-grid-div
.bubble-graph-card{
    width: 100%;
    height: 100%;
    grid-row: 2;
    grid-column: 1;

    border-right: 1px solid #c8cbd9;
}

.analytics-main-div
.graph-grid-div
.active-driver-card{
    width: 100%;
    height: 100%;
    grid-row: 2;
    grid-column: 2;

    border-right: 1px solid #c8cbd9;
}

.analytics-main-div
.graph-grid-div
.pie-chart-card{
    width: 100%;
    height: 100%;
    grid-row: 2;
    grid-column: 3;
}